.copyProjectWrapper .container{
    display: flex;
    justify-content: center;
}
.copyProjectWrapper .content-wrapper{
    padding: 50px;
    margin: 50px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 600px;
}
.copyProjectWrapper .content-wrapper label{
    display: block;
}
.copyProjectWrapper .content-wrapper .input-wrapper{
    margin-bottom: 20px;
}
.copyProjectWrapper .content-wrapper input{
    outline: none;
}
.copyProjectWrapper .content-wrapper .input-field{
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #999;
}
.copyProjectWrapper .content-wrapper .error-message{
    color: red;
}