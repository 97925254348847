.copy-datamapping h3{
    padding: 20px;
    border-bottom: 1px solid #999;
    margin: 0;
}
.copy-datamapping .btn-wrapper{
    padding: 20px;
}
.copy-datamapping .content-wrapper{
    padding: 20px;
}
.copy-datamapping label{
    font-weight: 400;
}
.copy-datamapping select{
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}
.pad-l-10{
    padding-left: 10px;
}