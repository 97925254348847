.h1 {
  margin-top: 10px;
  color: #0c0000;
  font-size: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}
.form {
  max-width: 96%;
  margin: 0 auto;
  position: relative;
}

.textCenterContainer {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.label {
  line-height: 1;
  text-align: left;
  display: block;
  margin-bottom: 8px;
  margin-top: 15px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}

.inputSubmit {
  background: #5d3ae6;
  color: white;
  border: none;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  left: 50%;
}

.button[type="submit"]:hover,
.inputSubmit:hover {
  background: #2fef25;
  color: black;
}

.inputSubmit:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.input:disabled {
  opacity: 0.4;
}

.inputSubmit:hover {
  transition: 0.3s all;
}
