.SwitchContainer {
  display: flex;
  background-color: #202020;
  align-items: center;
  border-radius: 10px;
  width: max-content;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.ToggleItem {
  height: 30px;
  width: max-content;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
}

.Text {
  color: white;
  font-size: 12px;
}

.InlineText {
  margin-top: 10px;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  font-size: medium;
  font-weight: 500;
}

.Input {
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 35px;
  font-size: medium;
  color: #646464;
}
.Field {
  margin-left: 10px;
  margin-right: 10px;
}
.Index {
  margin-left: 2px;
  margin-right: 1px;
}
.Close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  opacity: 0.8;
}
.DivGrid {
  display: grid;
  flex-direction: row;
}
.SubStringContainer {
  margin-left: 10px;
  grid-template-columns: 85px auto 5px 50px 5px;
}
.QueryMainContainer {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 10px;
  grid-template-columns: max-content auto;
  width: calc(100% - 20px);
  border: 2px solid #ccc;
  border-radius: 8px;
}
.QueryMainContainerSubGroup {
  margin-left: 40px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 10px;
  grid-template-columns: max-content auto;
  width: calc(100% - 50px);
  border: 2px solid #ccc;
  border-radius: 8px;
}
.QuerySubContainer {
  margin-bottom: 0px;
  align-items: center;
  grid-template-columns: 35% 10% auto 20px;
  width: 100%;
}
.QuerySubContainerSubGroup {
  margin-bottom: 0px;
  align-items: center;
  grid-template-columns: 33% 10% auto 20px;
  width: 100%;
}
.ParamTypeSwitchContainer {
  margin-bottom: 0px;
  grid-template-columns: 30px auto;
}
.BigIcon {
  margin: 20px;
  padding: 10px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: black;
  color: white;
}
.RightIconContainer {
  margin-right: 20px;
  margin-left: auto;
}
.Disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.RightSideOptionContainer {
  grid-template-columns: max-content auto;
  align-items: center;
}
.BetweenRightSideOptionContainer {
  display: grid;
  grid-template-columns: 100%;
}
.ConnectorContainer {
  display: grid;
  justify-items: center;
}
.ConnectorText {
  font-size: 16px;
  font-size: medium;
  font-family: "Tahoma";
  color: #646464;
  margin-top: 5px;
  margin-bottom: 5px;
}
.Sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999;
  width: 100%;
  background-color: #fbf7f7;
}
.Function {
  margin: 10px;
  height: 20px;
  width: auto;
}
.HeaderContainer {
  grid-template-columns: auto auto;
}
