#addNewButton>span #addNewButton>svg {
    font-size:1.3rem;
}
.dynamicselect_custom_form-wrapper{
    padding: 20px;
}
.dynamicselect_custom_form-wrapper .copy-source-wrapper{
    margin-top: 15px;
}
.dynamicselect_custom_form-wrapper .copy-source-wrapper select{
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}
