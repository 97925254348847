.mr-bt-20{
    margin-top: 20px;
    margin-bottom: 20px;
}
.pd-10{
    padding: 10px;
}
.member-table .rt-td{
    white-space: normal;
}
.connect-perprocess input{
    margin-right: 10px;
}
.connect-perprocess .error{
    color: red;
}