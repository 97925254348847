
.stickyGrid {
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    top: 24px; /* How far down the page you want your ToC to live */
    /* Give table of contents a scrollbar */
    max-height: 'calc(100vh - 180px)';
    overflow: 'auto'
}
.mainContentElement{
    overflow-x: hidden;
}
/* React editable table */
.react-table-wrapper{
    overflow-x: scroll;
    margin-top: 10px;
    margin-bottom: 30px;
}

.frd-table table, table.react-custom-editable-table{
    width: 100%;
    border-collapse: collapse; 
}
.frd-table table thead, table.react-custom-editable-table thead{
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
}
.frd-table table td, .frd-table table th, table.react-custom-editable-table td, table.react-custom-editable-table th{
    border: 1px solid rgba(0,0,0,0.1);
    padding: 4px;
}
.smartBlockWrapper > p {
    min-height: 20px;
}

.react-custom-editable-table .tableRow .commentIcon {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0
}
.react-custom-editable-table .tableRow:hover .commentIcon, .react-custom-editable-table .tableRow .active-comments {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0
}
.react-custom-editable-table .tableRow .comments-count{
    position: absolute;
    right: 5px;
    font-size: 12px;
    top: 1px;
    background: #F7E19C;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

/*  MainContent Styles start  */
.ReactModal__Overlay--after-open{
    z-index: 2;
}
.ReactModal__Content--after-open{
    padding: 0 !important;
}
.ReactModal__Overlay--after-open .requirement-summary-wrapper{
    margin: 20px !important;
}
.mainContent {
    position: relative;
    height: calc(100vh - 180px);
    overflow: scroll;
    padding-bottom: calc(100vh - 200px); /*  Workaround to set active for last element  */
    padding-right: 25px;
}
.title-wrapper{
    position: sticky !important;
    top: 0;
    z-index: 1;
}
.title-wrapper .navbar-right{
    margin-right: 0;
}
.title-wrapper .navbar-nav>li>a:focus{
    color: inherit;
    background-color: inherit;
}

.requirement-summary-wrapper h3{
    font-size: 21px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    margin-bottom: 15px;
    padding: 5px 0;
}
.requirement-summary-wrapper .navbar-default .navbar-nav>li>a:hover{
    background-color: transparent;
}
.requirement-summary-wrapper .navbar-default .navbar-nav>li>a span{
    cursor: default;
}
.requirement-summary-wrapper .navbar-default .navbar-nav>li>a input{
    background-color: transparent;
    min-width: 300px;
    border: 1px solid;
    border-radius: 4px;
    outline: none;
}
.editable-input input{
    width: 100%;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid;
}
.editable-input textarea{
    width: 100%;
    resize: none;
}
.requirement-summary-wrapper .rt-td{
    white-space: break-spaces;
}
.label-note{
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
}
/*  MainContent Styles End  */


/*  Sidebar Styles start  */
.sidebarContent {
    position: relative;
    max-height: calc(100vh - 180px);
    overflow: scroll;
}
.sidebarContent ul {
    padding-inline-start: 15px;
    margin-top: 5px;
    display: grid;
}
.sidebarContent .navbar-nav>li {
    margin: 5px 0px;
    float: none;
}
.sidebarContent ul.navbar-nav {
    list-style-type: none;
}
.sidebarContent li a {
    cursor: pointer;
}
.sidebarContent li .active {
    text-decoration: underline;
}
.sidebarContent li a:hover {
    background-color: transparent !important;
}
.sidebarContent li a:focus {
    background-color: transparent !important;
}
.sidebarContent .navbar-default .navbar-nav>li>a {
    padding: 0;
    display: block;
}
/*  Sidebar Styles End here  */

.generic-widgets-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

/*  Comments section Styles start  */
.drawerContainer {
    width: 535px;
    padding: 12px 24px;
    max-height: 100vh;
    overflow: hidden;
}
.drawerContainer .commentHeaderContainer{
    height: 5vh;
}
.drawerContainer .comments-section-container{
    height: 75vh;
    overflow: scroll;
}
.drawerContainer .comments-section-container .flex-box-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 100%;
}
.drawerContainer .comment-box-wrapper{
    height: 20vh;
}
.commentHeaderContainer  {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}
.commentHeaderContainer .commentHead {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    padding: 12px;
    padding-left: 0;
}
.commentHeaderContainer select {
    border: none;
    line-height: 1;
    outline: none;
    font-weight: 400;
    letter-spacing: 0px;
    padding: 12px;
}
.commentInput {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left; 
    padding: 5px 10px;
}
.buttonIcon {
    display: none;
}
tr:hover .buttonIcon {
    display: inline-block;
}
/*  Comments section Styles End here  */

/*  Comments Box Styles  */
.commentBox {
    display: flex;
    flex-wrap: wrap;
    margin: 18px auto;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #E0E0E0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: min-height 0.4s ease;
    max-height: 40px;
}
.commentBox .commentField {
    border: none;
    resize: none;
    transition: transform 0.4s ease;
    font-size: 13px;
    width: 100%;
    padding: 14px;
    line-height: 1;
    outline: none;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}
.commentBox.expanded .commentField {
    transform: translateY(12px);
    min-height: 60px;
}

.commentBox .actions {
    width: 100%;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: 0px;
    opacity: 0;
    padding: 6px 14px;
    transition: opacity 0.4s ease 0.2s;
    border-top: 1px solid #E0E0E0;
}
.commentBox.expanded .actions {
    opacity: 1;
}
.commentBox .actions button {
    margin-left: 6px;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    line-height: 1;
}
.commentBox .actions .cancel {
    background: none;
}
.commentBox .actions .submitBtn {
    color: #fff;
    background: #177B8F;
}
.commentBox .actions .commentTypeSelect {
    border: none;
    line-height: 1;
    outline: none;
    font-weight: 400;
    letter-spacing: 0px;
}
/*  Comments Box Styles End here  */

/* Comments Section */
.comment-container{
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
}
.comment-wrapper{
    display: flex;
    gap: 20px;
}
.comment-section-left{
    width: 10%;
}
.comment-section-right{
    width: 90%;
}
.user-badge-wrapper{
    position: relative;
    height: 40px;
    width: 40px;
    background: #b04a8f;
    text-transform: capitalize;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-weight: 800;
    font-size: 20px;
}
.comment-detail .user-email{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}
.comment-time{
    color: #999;
    font-size: 12px;
}
.comment-widget{
    display: flex;
    justify-content: space-between;
}
.comment-actions{
    display: flex;
    gap: 10px;
}
.comment-actions i{
    cursor: pointer;
    color: #666;
}
.mr-r-5{
    margin-right: 5px;
}
.reply-comment{
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid #E0E0E0;
}
.comment-actions ul{
    left: unset !important;
    right: 0 !important;
}
.comment-section-right .bg-success.badge{
    background: green;
}
/* Comments Section ends here*/

.notification-container {
    position: relative;
}
.notification-badge {   
    position: absolute;
    margin: 3px;
    border-radius: 7px;
    padding: 1px 4px;
    font-size: 10px;
    line-height: 12px;
}

.smartblock-container.is-full{
    margin: 0 !important;
    max-width: 100vw !important;
}

span.input-error{
    color: red;
    font-weight: 400;
    font-size: 14px;
}
.activeLinkStyle {
    color: #177B8F;
    margin-top: 10px;
    margin-bottom: -5px;
}

.activeLinkStyle:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* Example Table CSS Styles*/
.examplesTable{
    color: #77bdcb;
    margin-top: 10px;
    margin-bottom: -5px;
    border-style: solid;
}
.examplesTable .exampleHeaderTableCell{
    color: #ee880c;
    font-size: 12px;
    font-weight: bold;
    border-style: solid;
}
.examplesTable .exampleDataTableCell{
    color: #636869;
    font-size: 12px;
    font-weight: normal;
    border-style: solid;
}
/* Examples Section ends here*/
