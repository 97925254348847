#addNewButton>span #addNewButton>svg {
    font-size:1.3rem;
}
.dynamicselect_custom_form-wrapper{
    padding: 20px;
}
.dynamicselect_custom_form-wrapper .copy-source-wrapper{
    margin-top: 15px;
}
.dynamicselect_custom_form-wrapper .copy-source-wrapper select{
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.QueryBuilder_SwitchContainer__7OtGh {
  display: flex;
  background-color: #202020;
  align-items: center;
  border-radius: 10px;
  width: -webkit-max-content;
  width: max-content;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.QueryBuilder_ToggleItem__OV-wj {
  height: 30px;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
}

.QueryBuilder_Text__3ubS1 {
  color: white;
  font-size: 12px;
}

.QueryBuilder_InlineText__3xEWY {
  margin-top: 10px;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  font-size: medium;
  font-weight: 500;
}

.QueryBuilder_Input__2WifP {
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 35px;
  font-size: medium;
  color: #646464;
}
.QueryBuilder_Field__3gYGi {
  margin-left: 10px;
  margin-right: 10px;
}
.QueryBuilder_Index__3eigA {
  margin-left: 2px;
  margin-right: 1px;
}
.QueryBuilder_Close__2AhdB {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  opacity: 0.8;
}
.QueryBuilder_DivGrid__2ZXE0 {
  display: grid;
  flex-direction: row;
}
.QueryBuilder_SubStringContainer__QULNJ {
  margin-left: 10px;
  grid-template-columns: 85px auto 5px 50px 5px;
}
.QueryBuilder_QueryMainContainer__21Dy5 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 10px;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  width: calc(100% - 20px);
  border: 2px solid #ccc;
  border-radius: 8px;
}
.QueryBuilder_QueryMainContainerSubGroup__1BFSF {
  margin-left: 40px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 10px;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  width: calc(100% - 50px);
  border: 2px solid #ccc;
  border-radius: 8px;
}
.QueryBuilder_QuerySubContainer__eLnKl {
  margin-bottom: 0px;
  align-items: center;
  grid-template-columns: 35% 10% auto 20px;
  width: 100%;
}
.QueryBuilder_QuerySubContainerSubGroup__28Hlc {
  margin-bottom: 0px;
  align-items: center;
  grid-template-columns: 33% 10% auto 20px;
  width: 100%;
}
.QueryBuilder_ParamTypeSwitchContainer__13i3Q {
  margin-bottom: 0px;
  grid-template-columns: 30px auto;
}
.QueryBuilder_BigIcon___Ium- {
  margin: 20px;
  padding: 10px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: black;
  color: white;
}
.QueryBuilder_RightIconContainer__ee5Bl {
  margin-right: 20px;
  margin-left: auto;
}
.QueryBuilder_Disabled__2tfvI {
  opacity: 0.6;
  cursor: not-allowed;
}
.QueryBuilder_RightSideOptionContainer__1AjVW {
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  align-items: center;
}
.QueryBuilder_BetweenRightSideOptionContainer__1huRk {
  display: grid;
  grid-template-columns: 100%;
}
.QueryBuilder_ConnectorContainer__2EeHh {
  display: grid;
  justify-items: center;
}
.QueryBuilder_ConnectorText__2D-f- {
  font-size: 16px;
  font-size: medium;
  font-family: "Tahoma";
  color: #646464;
  margin-top: 5px;
  margin-bottom: 5px;
}
.QueryBuilder_Sticky__OUUf3 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999;
  width: 100%;
  background-color: #fbf7f7;
}
.QueryBuilder_Function__2Aokd {
  margin: 10px;
  height: 20px;
  width: auto;
}
.QueryBuilder_HeaderContainer__1FFIj {
  grid-template-columns: auto auto;
}

.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: "Inter", sans-serif;

  min-width: 400px;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
  box-shadow: 8px 8px 5px rgb(0 0 0 / 15%);
  border-radius: 8px;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
    --select-search-font: "Inter var", sans-serif;
  }
}

@media (prefers-color-scheme: dark) {
  .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
  }
}

body.is-dark-mode .select-search-container {
  --select-search-background: #000;
  --select-search-border: #313244;
  --select-search-selected: #89b4fa;
  --select-search-text: #fff;
  --select-search-subtle-text: #a6adc8;
  --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  border-radius: 3px;
  outline: none;
  font-family: var(--select-search-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-select {
  background: var(--select-search-background);
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-search-border);
  overflow: auto;
  max-height: 360px;
  border-radius: 0 0 8px 8px;
  min-width: 400px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-radius: 3px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-search-background);
  border: none;
  outline: none;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--select-search-border);
  color: var(--select-search-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}

.importSFDCMetadata_h1__2dd-o {
  margin-top: 10px;
  color: #0c0000;
  font-size: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}
.importSFDCMetadata_form__3Kkz6 {
  max-width: 96%;
  margin: 0 auto;
  position: relative;
}

.importSFDCMetadata_textCenterContainer__3dPrv {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.importSFDCMetadata_input__Q7yPw {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.importSFDCMetadata_label__RcrX2 {
  line-height: 1;
  text-align: left;
  display: block;
  margin-bottom: 8px;
  margin-top: 15px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}

.importSFDCMetadata_inputSubmit__36apq {
  background: #5d3ae6;
  color: white;
  border: none;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  left: 50%;
}

.importSFDCMetadata_button__2d8In[type="submit"]:hover,
.importSFDCMetadata_inputSubmit__36apq:hover {
  background: #2fef25;
  color: black;
}

.importSFDCMetadata_inputSubmit__36apq:active {
  transition: 0.3s all;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.importSFDCMetadata_input__Q7yPw:disabled {
  opacity: 0.4;
}

.importSFDCMetadata_inputSubmit__36apq:hover {
  transition: 0.3s all;
}

.ReactTable .rt-th, .ReactTable .rt-td {
    overflow: visible;
}
.copy-datamapping h3{
    padding: 20px;
    border-bottom: 1px solid #999;
    margin: 0;
}
.copy-datamapping .btn-wrapper{
    padding: 20px;
}
.copy-datamapping .content-wrapper{
    padding: 20px;
}
.copy-datamapping label{
    font-weight: 400;
}
.copy-datamapping select{
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}
.pad-l-10{
    padding-left: 10px;
}
.form-inline {
  width: 70%;
}
.form-inline .form-control {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.flexContainer {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: auto;
}

.flexItem {
  margin: 0px 0px 5px 15px;
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}

ul.react-autocomplete-input {
  max-height: 30%;
  overflow: scroll;
  z-index: 2;
}

.expandable {
  flex-grow: 1;
}

.flexContainer span {
  margin-left: auto;
  width: 100%;
}

.proj-select-form-group {
  width: 30%;
}

#root_Response .field-object .field {
  float: left;
  padding: 0px 0px 0px 0px;
}
#dataSourceDetailsForm .form-group .field-object .field {
  float: left;
  padding: 0px 10px 0px 0px;
}
#dataSourceDetailsForm .form-group .field-object fieldset > div:nth-child(3) {
  width: 70%;
}

.NewSection {
  padding: 5px 0px 30px 0px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  margin: 20px 0px 0px 0px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.expression {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border: 1px solid #cccccc;
}
.SubSection {
  margin: 35px;
}

.inline-table {
  width: 32%;
  display: inline-block;
  margin: 5px 5px 5px 5px;
}

.reactTable {
  margin: 10px 0px 10px 0px;
}

.control-label {
  padding: 1px 10px;
}
.btn-padded {
  margin: 5px 5px 5px 5px;
  border-radius: 4px;
}
.RightAlign {
  float: Right;
}
.Tabular {
  text-align: center;
}
.editableCell .form-control:focus {
  box-shadow: 0 0 0 0.1rem;
}
.ClickDisabled {
  cursor: not-allowed;
  opacity: 0.35;
}
.Clickable {
  cursor: pointer;
}
input[type="checkbox"] {
  cursor: pointer;
}
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.SummarySection {
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  padding: 20px;
}
.HelpSection {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 20px;
  background-color: lightgray;
}
.FRDSection {
  padding: 50px;
  border-width: 5px;
}

.extraMargins {
  margin: 0px 12px 12px;
}

.custom-unlock-notification {
  top: 42px;
}

.spanLinkStyle:hover {
  cursor: pointer;
  text-decoration: underline;
}

#root_Response legend {
  font-size: 14px;
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}

.btn-src-upload {
  display: block;
  margin: 0px 20px 0px auto;
}

@media (max-width: 1199px) {
  .RuleMenu .navbar-header {
    float: none;
  }
  .RuleMenu .navbar-toggle {
    display: block;
  }
  .RuleMenu .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .RuleMenu .navbar-collapse.collapse {
    display: none !important;
  }
  .RuleMenu .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .RuleMenu .navbar-nav > li {
    float: none;
  }
  .RuleMenu .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .RuleMenu .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .RuleMenu .navbar-collapse.collapse.in {
    display: block !important;
  }
  .RuleMenu .collapsing {
    overflow: hidden !important;
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

#controlled-tab-example-pane-ApproverGroups .rt-td{
    white-space: break-spaces !important;
}
.copyProjectWrapper .container{
    display: flex;
    justify-content: center;
}
.copyProjectWrapper .content-wrapper{
    padding: 50px;
    margin: 50px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 600px;
}
.copyProjectWrapper .content-wrapper label{
    display: block;
}
.copyProjectWrapper .content-wrapper .input-wrapper{
    margin-bottom: 20px;
}
.copyProjectWrapper .content-wrapper input{
    outline: none;
}
.copyProjectWrapper .content-wrapper .input-field{
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #999;
}
.copyProjectWrapper .content-wrapper .error-message{
    color: red;
}
.tooltip-inner {
    max-width: 100%;
  }
.mr-bt-20{
    margin-top: 20px;
    margin-bottom: 20px;
}
.pd-10{
    padding: 10px;
}
.member-table .rt-td{
    white-space: normal;
}
.connect-perprocess input{
    margin-right: 10px;
}
.connect-perprocess .error{
    color: red;
}

.stickyGrid {
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    top: 24px; /* How far down the page you want your ToC to live */
    /* Give table of contents a scrollbar */
    max-height: 'calc(100vh - 180px)';
    overflow: 'auto'
}
.mainContentElement{
    overflow-x: hidden;
}
/* React editable table */
.react-table-wrapper{
    overflow-x: scroll;
    margin-top: 10px;
    margin-bottom: 30px;
}

.frd-table table, table.react-custom-editable-table{
    width: 100%;
    border-collapse: collapse; 
}
.frd-table table thead, table.react-custom-editable-table thead{
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
}
.frd-table table td, .frd-table table th, table.react-custom-editable-table td, table.react-custom-editable-table th{
    border: 1px solid rgba(0,0,0,0.1);
    padding: 4px;
}
.smartBlockWrapper > p {
    min-height: 20px;
}

.react-custom-editable-table .tableRow .commentIcon {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0
}
.react-custom-editable-table .tableRow:hover .commentIcon, .react-custom-editable-table .tableRow .active-comments {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0
}
.react-custom-editable-table .tableRow .comments-count{
    position: absolute;
    right: 5px;
    font-size: 12px;
    top: 1px;
    background: #F7E19C;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

/*  MainContent Styles start  */
.ReactModal__Overlay--after-open{
    z-index: 2;
}
.ReactModal__Content--after-open{
    padding: 0 !important;
}
.ReactModal__Overlay--after-open .requirement-summary-wrapper{
    margin: 20px !important;
}
.mainContent {
    position: relative;
    height: calc(100vh - 180px);
    overflow: scroll;
    padding-bottom: calc(100vh - 200px); /*  Workaround to set active for last element  */
    padding-right: 25px;
}
.title-wrapper{
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 1;
}
.title-wrapper .navbar-right{
    margin-right: 0;
}
.title-wrapper .navbar-nav>li>a:focus{
    color: inherit;
    background-color: inherit;
}

.requirement-summary-wrapper h3{
    font-size: 21px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    margin-bottom: 15px;
    padding: 5px 0;
}
.requirement-summary-wrapper .navbar-default .navbar-nav>li>a:hover{
    background-color: transparent;
}
.requirement-summary-wrapper .navbar-default .navbar-nav>li>a span{
    cursor: default;
}
.requirement-summary-wrapper .navbar-default .navbar-nav>li>a input{
    background-color: transparent;
    min-width: 300px;
    border: 1px solid;
    border-radius: 4px;
    outline: none;
}
.editable-input input{
    width: 100%;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid;
}
.editable-input textarea{
    width: 100%;
    resize: none;
}
.requirement-summary-wrapper .rt-td{
    white-space: break-spaces;
}
.label-note{
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
}
/*  MainContent Styles End  */


/*  Sidebar Styles start  */
.sidebarContent {
    position: relative;
    max-height: calc(100vh - 180px);
    overflow: scroll;
}
.sidebarContent ul {
    -webkit-padding-start: 15px;
            padding-inline-start: 15px;
    margin-top: 5px;
    display: grid;
}
.sidebarContent .navbar-nav>li {
    margin: 5px 0px;
    float: none;
}
.sidebarContent ul.navbar-nav {
    list-style-type: none;
}
.sidebarContent li a {
    cursor: pointer;
}
.sidebarContent li .active {
    text-decoration: underline;
}
.sidebarContent li a:hover {
    background-color: transparent !important;
}
.sidebarContent li a:focus {
    background-color: transparent !important;
}
.sidebarContent .navbar-default .navbar-nav>li>a {
    padding: 0;
    display: block;
}
/*  Sidebar Styles End here  */

.generic-widgets-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

/*  Comments section Styles start  */
.drawerContainer {
    width: 535px;
    padding: 12px 24px;
    max-height: 100vh;
    overflow: hidden;
}
.drawerContainer .commentHeaderContainer{
    height: 5vh;
}
.drawerContainer .comments-section-container{
    height: 75vh;
    overflow: scroll;
}
.drawerContainer .comments-section-container .flex-box-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 100%;
}
.drawerContainer .comment-box-wrapper{
    height: 20vh;
}
.commentHeaderContainer  {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}
.commentHeaderContainer .commentHead {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    padding: 12px;
    padding-left: 0;
}
.commentHeaderContainer select {
    border: none;
    line-height: 1;
    outline: none;
    font-weight: 400;
    letter-spacing: 0px;
    padding: 12px;
}
.commentInput {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left; 
    padding: 5px 10px;
}
.buttonIcon {
    display: none;
}
tr:hover .buttonIcon {
    display: inline-block;
}
/*  Comments section Styles End here  */

/*  Comments Box Styles  */
.commentBox {
    display: flex;
    flex-wrap: wrap;
    margin: 18px auto;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #E0E0E0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: min-height 0.4s ease;
    max-height: 40px;
}
.commentBox .commentField {
    border: none;
    resize: none;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    font-size: 13px;
    width: 100%;
    padding: 14px;
    line-height: 1;
    outline: none;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}
.commentBox.expanded .commentField {
    -webkit-transform: translateY(12px);
            transform: translateY(12px);
    min-height: 60px;
}

.commentBox .actions {
    width: 100%;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: 0px;
    opacity: 0;
    padding: 6px 14px;
    transition: opacity 0.4s ease 0.2s;
    border-top: 1px solid #E0E0E0;
}
.commentBox.expanded .actions {
    opacity: 1;
}
.commentBox .actions button {
    margin-left: 6px;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    line-height: 1;
}
.commentBox .actions .cancel {
    background: none;
}
.commentBox .actions .submitBtn {
    color: #fff;
    background: #177B8F;
}
.commentBox .actions .commentTypeSelect {
    border: none;
    line-height: 1;
    outline: none;
    font-weight: 400;
    letter-spacing: 0px;
}
/*  Comments Box Styles End here  */

/* Comments Section */
.comment-container{
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
}
.comment-wrapper{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}
.comment-section-left{
    width: 10%;
}
.comment-section-right{
    width: 90%;
}
.user-badge-wrapper{
    position: relative;
    height: 40px;
    width: 40px;
    background: #b04a8f;
    text-transform: capitalize;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-weight: 800;
    font-size: 20px;
}
.comment-detail .user-email{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}
.comment-time{
    color: #999;
    font-size: 12px;
}
.comment-widget{
    display: flex;
    justify-content: space-between;
}
.comment-actions{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.comment-actions i{
    cursor: pointer;
    color: #666;
}
.mr-r-5{
    margin-right: 5px;
}
.reply-comment{
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid #E0E0E0;
}
.comment-actions ul{
    left: unset !important;
    right: 0 !important;
}
.comment-section-right .bg-success.badge{
    background: green;
}
/* Comments Section ends here*/

.notification-container {
    position: relative;
}
.notification-badge {   
    position: absolute;
    margin: 3px;
    border-radius: 7px;
    padding: 1px 4px;
    font-size: 10px;
    line-height: 12px;
}

.smartblock-container.is-full{
    margin: 0 !important;
    max-width: 100vw !important;
}

span.input-error{
    color: red;
    font-weight: 400;
    font-size: 14px;
}
.activeLinkStyle {
    color: #177B8F;
    margin-top: 10px;
    margin-bottom: -5px;
}

.activeLinkStyle:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* Example Table CSS Styles*/
.examplesTable{
    color: #77bdcb;
    margin-top: 10px;
    margin-bottom: -5px;
    border-style: solid;
}
.examplesTable .exampleHeaderTableCell{
    color: #ee880c;
    font-size: 12px;
    font-weight: bold;
    border-style: solid;
}
.examplesTable .exampleDataTableCell{
    color: #636869;
    font-size: 12px;
    font-weight: normal;
    border-style: solid;
}
/* Examples Section ends here*/

@charset "UTF-8";
  .smartblock-btn {
    width: 36px;
    height: 36px;
    border: none;
    margin-right: 5px;
    padding: 5px;
    border-radius: 3px;
    appearance: none;
    -webkit-appearance: none;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    border: 1px solid transparent;
  }
  .smartblock-btn svg {
    fill: currentColor;
    vertical-align: middle;
  }
  .smartblock-btn:last-child {
    margin-right: 0;
  }
  .smartblock-btn:not([disabled]):hover {
    color: #005cee;
  }
  .smartblock-btn[disabled]:hover {
    cursor: not-allowed;
  }
  .smartblock-btn.is-black {
    background-color: #333333 !important;
    color: #fff;
  }
  .smartblock-btn.is-disabled {
    opacity: 0.4;
  }
  .smartblock-btn.is-active {
    color: #005cee;
    opacity: 1;
    background-color: #f2f2f4;
  }
  .smartblock-btn.is-active:focus {
    outline: 0;
    color: #005cee;
    background-color: #f2f2f4;
  }
  .smartblock-btn:not(.is-active) {
    color: #777;
  }
  .smartblock-btn:not(.is-active):focus {
    outline: 0;
    color: #777;
    background: #fff;
  }
  
  @-webkit-keyframes smartblock-backbtn-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes smartblock-backbtn-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .smartblock-backbtn {
    background: #f2f2f4;
    width: 44px;
    height: 44px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10;
    color: #014cc5;
    -webkit-appearance: none;
            appearance: none;
    border: none;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-animation: smartblock-backbtn-appear 0.3s;
            animation: smartblock-backbtn-appear 0.3s;
  }
  .smartblock-backbtn svg {
    fill: currentColor;
  }
  
  @-webkit-keyframes smartblock-edit-menu-fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes smartblock-edit-menu-fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .smartblock-edit-menu {
    display: flex;
    position: absolute;
    right: 0;
    z-index: 10;
    max-width: 280px;
    -webkit-animation: smartblock-edit-menu-fadein 0.3s;
            animation: smartblock-edit-menu-fadein 0.3s;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 4px;
    background-color: #f2f2f4;
  }
  
  .smartblock-edit-btn {
    margin: 3px;
    width: 36px;
    height: 36px;
    background-color: transparent;
    color: #666;
  }
  .smartblock-edit-btn.is-black {
    background-color: #444 !important;
    color: #fff;
  }
  .smartblock-edit-btn.is-black:hover {
    background-color: #111111 !important;
    color: #fff !important;
  }
  .smartblock-edit-btn:hover {
    color: #111;
  }
  .smartblock-edit-btn:last-child {
    margin-right: 2px;
  }
  
  .smartblock-cell-btn .icon {
    font-size: 30px;
  }
  
  @-webkit-keyframes smartblock-inline-menu-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes smartblock-inline-menu-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .smartblock-inline-menu {
    position: absolute;
    z-index: 12;
    -webkit-animation: smartblock-inline-menu-appear 0.3s;
            animation: smartblock-inline-menu-appear 0.3s;
    margin-top: 8px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 0 3px 40px 8px rgba(116, 116, 116, 0.2);
  }
  
  .smartblock-inline-menu-arrow {
    position: absolute;
    top: -12px;
    content: "";
    display: block;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #ffffff transparent;
  }
  
  .smartblock-inline-menu-inner {
    padding: 5px;
    display: flex;
    align-items: baseline;
  }
  
  @-webkit-keyframes smartblock-menu-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes smartblock-menu-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .smartblock-menu {
    position: absolute;
    z-index: 11;
    -webkit-animation: smartblock-menu-appear 0.3s;
            animation: smartblock-menu-appear 0.3s;
    border-radius: 5px;
    box-shadow: 0 3px 40px 8px rgba(116, 116, 116, 0.2);
    padding: 5px 0;
    color: #767676;
    background-color: #fff;
  }
  .smartblock-menu:before {
    position: absolute;
    left: 20px;
    top: -12px;
    content: "";
    display: block;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #ffffff transparent;
  }
  
  .smartblock-menu-top {
    padding: 0 5px 0 5px;
  }
  
  .smartblock-custom-menu {
    border-top: 1px solid #ccc;
    margin: 5px 0 0 0;
    padding: 5px 5px 0 5px;
  }
  
  .smartblock-container {
    margin: 0 auto;
    padding: 10px 0 80px 0;
  }
  .smartblock-container.is-full {
    max-width: 780px;
  }
  
  .smartblock-input-area {
    width: 100%;
    overflow-y: auto;
  }
  
  .smartblock-inner {
    position: relative;
  }
  
  .smartblock-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .smartblock-popup-inner {
    flex-basis: 380px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 3px 10px 4px rgba(116, 116, 116, 0.2);
    padding: 15px;
    box-sizing: border-box;
  }
  .smartblock-popup-inner input {
    display: block;
    flex: 1 1;
    width: 100%;
    padding: 0 5px;
    font-size: 16px;
    line-height: 30px;
    box-sizing: border-box;
    border-radius: 3px 0 0 3px;
    border: 1px solid #eee;
  }
  .smartblock-popup-inner input:focus {
    outline: 0;
    border: 1px solid #137af3;
    box-shadow: 0 0 0 2px rgba(19, 122, 243, 0.4),
      inset 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  
  .smartblock-popup-text {
    margin: 0 0 15px 0;
    color: #333;
    font-size: 16px;
  }
  
  .smartblock-popup-btn {
    width: 38px;
    height: 38px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    border: 1px solid transparent;
    background-color: #014cc5;
  }
  .smartblock-popup-btn:focus {
    outline: 0;
    border: 1px solid #137af3;
    box-shadow: 0 0 0 2px rgba(19, 122, 243, 0.4),
      inset 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  
  .smartblock-popup-field {
    display: flex;
  }
  
  .smartblock-popup-view-btn {
    background-color: #fff;
    border: none;
    color: #adadad;
    margin-left: 5px;
    vertical-align: middle;
  }
  
  .smartblock-tooltip-wrap {
    position: absolute;
    display: inline-block;
    z-index: 1000;
    background-color: #fff;
  }
  
  .smartblock-tooltip-wrap-arrow {
    position: absolute;
    top: -12px;
    content: "";
    display: block;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #ffffff transparent;
  }
  
  .smartblock-tooltip {
    color: #777;
    font-size: 16px;
    box-shadow: 0 3px 40px 8px rgba(116, 116, 116, 0.2);
    border-radius: 3px;
    width: 320px;
    line-height: 46px;
    display: block;
  }
  
  .smartblock-tooltip-inner {
    display: flex;
    height: 46px;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .smartblock-tooltip-input {
    border: none;
    display: block;
    padding: 0 10px;
    font-size: 16px;
    flex: 1 1;
  }
  .smartblock-tooltip-input:focus {
    outline: none;
  }
  
  .smartblock-tooltip-btn {
    border: none;
    background-color: #014cc5;
    width: 46px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .ProseMirror {
    padding: 10px 5px;
    background: #fff;
  }
  
  .ProseMirror:focus {
    outline: none;
  }
  
  .ProseMirror .tableWrapper {
    overflow-x: auto;
  }
  
  .ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
  }
  
  .ProseMirror td,
  .ProseMirror th {
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
  }
  
  .ProseMirror .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none;
  }
  
  .ProseMirror.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  
  /* Give selected cells a blue overlay */
  .ProseMirror .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }
  
  .ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;
  }
  
  .ProseMirror-gapcursor:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    border-top: 1px solid black;
    -webkit-animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
            animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  }
  
  @-webkit-keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }
  
  @keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }
  
  .ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }
  
  .ProseMirror{
      padding: 0 !important;
  }
  
  .ProseMirror figure {
    text-align: center;
    padding: 10px 5px;
    margin: 0;
  }
  .ProseMirror figure.empty-node figcaption:before {
    content: "Type Something...";
  }
  .ProseMirror figure figcaption {
    font-size: 0;
  }
  .ProseMirror figure figcaption.caption {
    font-size: 14px;
  }
  .ProseMirror figure.empty-node figcaption.not-active:before {
    content: "";
  }
  
  .ProseMirror .empty-node::before {
    float: left;
    color: #aaa;
    pointer-events: none;
    height: 0;
    font-style: italic;
  }
  
  .ProseMirror p.empty-node:first-child::before {
    content: "Start typing\2026";
  }
  
  .ProseMirror figcaption {
    color: #333;
    font-size: 12px;
  }
  
  .ProseMirror img {
    max-width: 100%;
  }
  
  .ProseMirror a {
    color: #014cc5;
  }
  
  .ProseMirror hr {
    padding: 2px 10px;
    border: none;
    margin: 1em 0;
  }
  
  .ProseMirror hr:after {
    content: "";
    display: block;
    height: 1px;
    background-color: silver;
    line-height: 2px;
  }
  
  .ProseMirror *:first-child {
    margin-top: 0;
  }
  
  .ProseMirror p {
    margin: 0 0 10px 0;
    padding: 5px;
    line-height: 1.6;
    font-size: 1.4rem;
    border-radius: 3px;
    transition: background-color 0.3s;
    box-sizing: border-box;
  }
  
  .ProseMirror table p {
    margin: 0;
    line-height: 1;
  }
  
  @media screen and (min-width: 768px) {
    .ProseMirror p {
      padding: 5px 10px;
    }
  }
  
  .ProseMirror h1 {
    margin: 0 0 10px 0;
    padding: 0 5px;
    font-size: 1.875rem;
    line-height: 1.3;
  }
  
  @media screen and (min-width: 768px) {
    .ProseMirror h1 {
      padding: 0 10px;
      font-size: 2.125rem;
    }
  }
  
  .ProseMirror h2,
  .ProseMirror h3 {
    margin: 0 0 10px 0;
    padding: 5px;
    line-height: 1.4;
    border-radius: 3px;
    transition: background-color 0.3s;
    box-sizing: border-box;
  }
  
  .ProseMirror h2 {
    font-size: 1.625rem;
  }
  
  .ProseMirror h3 {
    font-size: 1.375rem;
  }
  
  @media screen and (min-width: 768px) {
    .ProseMirror h2,
    .ProseMirror h3 {
      padding: 5px 10px;
    }
  }
  
  .ProseMirror ul,
  .ProseMirror ol {
    margin: 0 0 30px 0;
    padding: 5px 5px 5px 30px;
    font-size: 1rem;
    line-height: 1.4;
    box-sizing: border-box;
  }
  
  .ProseMirror li > p {
    margin: 0;
    padding: 0;
    min-height: auto;
  }
  
  .ProseMirror li ul,
  .ProseMirror li ol {
    margin: 0;
  }
  
  .ProseMirror blockquote {
    position: relative;
    margin: 0 0 30px 0;
    padding: 10px 5px 10px 25px;
    font-size: 0.875rem;
    line-height: 1.5;
    box-sizing: border-box;
  }
  
  .ProseMirror blockquote:before {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 10px;
    border-left: 5px solid #d3d3d3;
  }
  
  @media screen and (min-width: 768px) {
    .ProseMirror blockquote {
      padding: 10px 10px 10px 30px;
    }
  }
  
  .ProseMirror th,
  .ProseMirror td {
    border: 1px solid #eee;
    padding: 2px 5px;
  }
  
  .ProseMirror sup,
  .ProseMirror sub {
    line-height: 0;
  }
  
  .ProseMirror .selected {
    background-color: #f2f2f4;
    border-radius: 3px;
  }
  
  .ProseMirrorHideSelection .selected {
    background-color: transparent;
  }
  
  .ProseMirror .ProseMirror-widget {
    color: #adadad;
  }
  
  .ProseMirror td,
  .ProseMirror th {
    border-color: #767676;
  }
  
  .ProseMirror table {
    margin-bottom: 15px;
    border-color: #767676;
  }
  
  .ProseMirror table p.empty-node:first-child::before {
    display: none;
  }
  
  .ProseMirror h1.empty-node::before {
    content: "Type Title here";
    font-style: normal;
  }
  
  .ProseMirror p.empty-node:first-child::before {
    content: "Type Something.." !important;
    font-style: normal;
  }
  
  .ProseMirror ::selection {
    color: #014cc5;
    background: #d1ddf0;
    border-right: 1px solid #014cc5;
    border-left: 1px solid #014cc5;
    caret-color: red;
  }
  
  .ProseMirror figure {
    pointer-events: none;
    color: transparent;
  }
  
  .ProseMirror .caption {
    color: #909090;
    display: block;
    text-align: center;
  }
  
  .ProseMirror .media {
    padding: 10px;
    margin: 0;
    display: block;
  }
  
  .ProseMirror .media.selected {
    position: relative;
    background-color: #f2f2f4;
  }
  
  .ProseMirror .media img.small {
    width: 320px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .ProseMirror .media img {
    max-width: 100%;
    height: auto;
    cursor: default;
  }
  
  .ProseMirror .media .caption {
    color: #333;
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .ProseMirror .youtube-frame-wrap {
    margin-bottom: 30px;
    padding: 10px 40px;
  }
  
  .ProseMirror .embed-wrap {
    margin: 0 5px 30px 5px;
    position: relative;
    z-index: 10;
    padding: 5px;
  }
  
  .ProseMirror .embed {
    position: relative;
    display: block;
    padding: 20px;
    color: #666;
    font-size: 14px;
    background: #fff;
    border: 1px solid rgba(201, 201, 204, 0.48);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  .ProseMirror .embed:link,
  .ProseMirror .embed:hover,
  .ProseMirror .embed:visited {
    color: #666;
    text-decoration: none;
  }
  
  .ProseMirror .youtube-frame {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .ProseMirror .youtube-frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .ProseMirror pre,
  .ProseMirror pre.selected {
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
  }
  
  /* a11y-dark theme */
  /* Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css */
  /* @author: ericwbailey */
  .ProseMirror {
    /* Comment */
    /* Red */
    /* Orange */
    /* Yellow */
    /* Green */
    /* Blue */
    /* Purple */
  }
  .ProseMirror .hljs-comment,
  .ProseMirror .hljs-quote {
    color: #d4d0ab;
  }
  .ProseMirror .hljs-variable,
  .ProseMirror .hljs-template-variable,
  .ProseMirror .hljs-tag,
  .ProseMirror .hljs-name,
  .ProseMirror .hljs-selector-id,
  .ProseMirror .hljs-selector-class,
  .ProseMirror .hljs-regexp,
  .ProseMirror .hljs-deletion {
    color: #ffa07a;
  }
  .ProseMirror .hljs-number,
  .ProseMirror .hljs-built_in,
  .ProseMirror .hljs-builtin-name,
  .ProseMirror .hljs-literal,
  .ProseMirror .hljs-type,
  .ProseMirror .hljs-params,
  .ProseMirror .hljs-meta,
  .ProseMirror .hljs-link {
    color: #f5ab35;
  }
  .ProseMirror .hljs-attribute {
    color: #ffd700;
  }
  .ProseMirror .hljs-string,
  .ProseMirror .hljs-symbol,
  .ProseMirror .hljs-bullet,
  .ProseMirror .hljs-addition {
    color: #abe338;
  }
  .ProseMirror .hljs-title,
  .ProseMirror .hljs-section {
    color: #00e0e0;
  }
  .ProseMirror .hljs-keyword,
  .ProseMirror .hljs-selector-tag {
    color: #dcc6e0;
  }
  .ProseMirror .hljs {
    display: block;
    overflow-x: auto;
    background: #2b2b2b;
    color: #f8f8f2;
    padding: 0.5em;
  }
  .ProseMirror .hljs-emphasis {
    font-style: italic;
  }
  .ProseMirror .hljs-strong {
    font-weight: bold;
  }
  @media screen and (-ms-high-contrast: active) {
    .ProseMirror .hljs-addition,
    .ProseMirror .hljs-attribute,
    .ProseMirror .hljs-built_in,
    .ProseMirror .hljs-builtin-name,
    .ProseMirror .hljs-bullet,
    .ProseMirror .hljs-comment,
    .ProseMirror .hljs-link,
    .ProseMirror .hljs-literal,
    .ProseMirror .hljs-meta,
    .ProseMirror .hljs-number,
    .ProseMirror .hljs-params,
    .ProseMirror .hljs-string,
    .ProseMirror .hljs-symbol,
    .ProseMirror .hljs-type,
    .ProseMirror .hljs-quote {
      color: highlight;
    }
    .ProseMirror .hljs-keyword,
    .ProseMirror .hljs-selector-tag {
      font-weight: bold;
    }
  }
  
