.form-inline {
  width: 70%;
}
.form-inline .form-control {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.flexContainer {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: auto;
}

.flexItem {
  margin: 0px 0px 5px 15px;
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}

ul.react-autocomplete-input {
  max-height: 30%;
  overflow: scroll;
  z-index: 2;
}

.expandable {
  flex-grow: 1;
}

.flexContainer span {
  margin-left: auto;
  width: 100%;
}

.proj-select-form-group {
  width: 30%;
}

#root_Response .field-object .field {
  float: left;
  padding: 0px 0px 0px 0px;
}
#dataSourceDetailsForm .form-group .field-object .field {
  float: left;
  padding: 0px 10px 0px 0px;
}
#dataSourceDetailsForm .form-group .field-object fieldset > div:nth-child(3) {
  width: 70%;
}

.NewSection {
  padding: 5px 0px 30px 0px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  margin: 20px 0px 0px 0px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.expression {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border: 1px solid #cccccc;
}
.SubSection {
  margin: 35px;
}

.inline-table {
  width: 32%;
  display: inline-block;
  margin: 5px 5px 5px 5px;
}

.reactTable {
  margin: 10px 0px 10px 0px;
}

.control-label {
  padding: 1px 10px;
}
.btn-padded {
  margin: 5px 5px 5px 5px;
  border-radius: 4px;
}
.RightAlign {
  float: Right;
}
.Tabular {
  text-align: center;
}
.editableCell .form-control:focus {
  box-shadow: 0 0 0 0.1rem;
}
.ClickDisabled {
  cursor: not-allowed;
  opacity: 0.35;
}
.Clickable {
  cursor: pointer;
}
input[type="checkbox"] {
  cursor: pointer;
}
div.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}
.SummarySection {
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  padding: 20px;
}
.HelpSection {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 20px;
  background-color: lightgray;
}
.FRDSection {
  padding: 50px;
  border-width: 5px;
}

.extraMargins {
  margin: 0px 12px 12px;
}

.custom-unlock-notification {
  top: 42px;
}

.spanLinkStyle:hover {
  cursor: pointer;
  text-decoration: underline;
}

#root_Response legend {
  font-size: 14px;
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}

.btn-src-upload {
  display: block;
  margin: 0px 20px 0px auto;
}

@media (max-width: 1199px) {
  .RuleMenu .navbar-header {
    float: none;
  }
  .RuleMenu .navbar-toggle {
    display: block;
  }
  .RuleMenu .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .RuleMenu .navbar-collapse.collapse {
    display: none !important;
  }
  .RuleMenu .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .RuleMenu .navbar-nav > li {
    float: none;
  }
  .RuleMenu .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .RuleMenu .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .RuleMenu .navbar-collapse.collapse.in {
    display: block !important;
  }
  .RuleMenu .collapsing {
    overflow: hidden !important;
  }
}
